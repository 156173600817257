import React from 'react'
import "./noMatch.css"

const NoMatch = () => {
    return (
        <div className="nomatch-div">
            <h1 className="nomatch-title">404</h1>
            <h2 className="nomatch-subtitle">la pagina no existe</h2>
        </div>
    )
}

export default NoMatch
