import { createContext, useReducer } from "react";
// Acciones
const CHANGE_TITLE = "CHANGE_TITLE";

// Reducer
const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_TITLE:
      return {
        ...payload
      };
    default:
      return state;
  }
};

const initialState = {
  title: "",
  url: "",
};

export const TitleContext = createContext(initialState);

const TitleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeTitle = ({ title, url }) => {
    dispatch({
      type: CHANGE_TITLE,
      payload: { title, url },
    });
  };

  return <TitleContext.Provider value={{ state, changeTitle }}>{children}</TitleContext.Provider>;
};

export default TitleProvider;
