import React, { useContext, useEffect, useState } from "react";
import "./wppButton.css";
import { IconContext } from "react-icons";
import { ImWhatsapp } from "react-icons/im";
import { useLocation } from "react-router";
import { TitleContext } from "../../context/titleContext";
const WhatsAppButton = () => {
  const [text, setText] = useState("Hola! Me gustaria hacerte una consulta");
  const { state } = useContext(TitleContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (state.title && /\/producto\//.test(pathname)) {
      setText(`Hola! Me gustaria hacerte una consulta sobre: ${state.title}
      ${state.url}`);
    } else {
      setText("Hola! Me gustaria hacerte una consulta.");
    }
  }, [state, pathname]);
  return (
    <a
      href={`https://api.whatsapp.com/send/?phone=5493541659041&text=${encodeURIComponent(text)}&app_absent=0`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="wps-main">
        <IconContext.Provider value={{ className: "wps-icon" }}>
          <ImWhatsapp />
        </IconContext.Provider>
      </div>
    </a>
  );
};

export default WhatsAppButton;
